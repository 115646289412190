import React, { useEffect, useState } from "react";
import { findIndex, map } from "lodash";

import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { Button, ParameterForm } from "components";
import { AiOutlinePlus } from "react-icons/ai";
import { FormModal } from "components/Modal";

import IndicatorForm from "./indicatorForm";
import IndicatorItem from "./indicatorItem";

const SubOutputIndicators = ({ subOutputIndex }) => {
  const { control } = useFormContext();

  const {
    fields: fieldsIndicator,
    append: appendIndicator,
    remove: removeIndicator,
    update: updateIndicator,
  } = useFieldArray({
    control: control,
    name: `subOutputs.${subOutputIndex}.indicators`,
  });

  const formIndicator = useForm({
    defaultValues: {
      numberingFormat: "",
      nama: "",
      deskripsi: "",
      parameter: [],
      id: "",
    },
  });

  const [isOpenForm, setOpenForm] = useState(false);
  const [isFormEdit, setIsFormEdit] = useState(false);

  const handleAdd = () => {
    setOpenForm(true);
  };

  const handleEdit = (data) => {
    setIsFormEdit(true);

    formIndicator.setValue("id", data.id);
    formIndicator.setValue("numberingFormat", data.numberingFormat);
    formIndicator.setValue("nama", data.nama);
    formIndicator.setValue("deskripsi", data.deskripsi);
    formIndicator.setValue("parameter", [...data.parameter]);

    setOpenForm(true);
  };

  const handleDelete = (data) => {
    const index = findIndex(fieldsIndicator, (field) => field.id === data.id);

    if (index >= 0) {
      removeIndicator(index);
    }
  };

  const handleSubmit = () => {
    const { id: payloadId, ...payload } = formIndicator.getValues();

    if (isFormEdit && !!payloadId) {
      const index = findIndex(fieldsIndicator, ({ id }) => id === payloadId);

      updateIndicator(index, { ...payload, id: payloadId });
      setIsFormEdit(false);
    } else {
      appendIndicator(payload);
    }

    formIndicator.reset({
      numberingFormat: "",
      nama: "",
      deskripsi: "",
      parameter: [],
      id: "",
    });
    setOpenForm(false);
  };

  useEffect(() => {
    if (!isOpenForm) {
      setIsFormEdit(false);

      formIndicator.reset({
        numberingFormat: "",
        nama: "",
        deskripsi: "",
        parameter: [],
        id: "",
      });
    }
  }, [formIndicator, isOpenForm]);

  return (
    <>
      <div>
        {fieldsIndicator?.length > 0 ? (
          <div className="space-y-4 my-6">
            {map(fieldsIndicator, (data) => (
              <IndicatorItem
                key={data.id}
                data={data}
                onEdit={() => handleEdit(data)}
                onDelete={() => handleDelete(data)}
              />
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-400 my-8">
            No data has been added yet
          </div>
        )}
      </div>

      <div className="px-3 pb-4 flex items-center justify-end">
        <Button
          className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
          startIcon={<AiOutlinePlus />}
          onClick={handleAdd}
        >
          Tambah Indicator
        </Button>
      </div>

      <FormModal
        title={isFormEdit ? "Edit Indicator" : "Tambah Indicator"}
        open={isOpenForm}
        onClose={setOpenForm}
        onSubmit={formIndicator.handleSubmit(handleSubmit)}
      >
        <FormProvider {...formIndicator}>
          <div className="space-y-5">
            <IndicatorForm />
            <ParameterForm open={isOpenForm} />
          </div>
        </FormProvider>
      </FormModal>
    </>
  );
};

export default SubOutputIndicators;
